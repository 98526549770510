.App {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #222423;
}

.logo {
  height: 20vmin;
}